<template>
  <div class="induction">
    <!-- 待入职人员 -->
    <van-nav-bar
      title="待入职"
      left-text
      left-arrow
      @click-left="onClickLeft"
      @click-right="onClickRight"
    />
    <!-- 使用 title 插槽来自定义标题 -->
    <!-- 日期控件 -->
    <my-calendar @outDate="getdate" />
    <div class="add_newone">
      <van-cell is-link @click="gotoAdd">
        <template #title>
          <svg-icon icon-class="induction_add"></svg-icon>
          <span class="custom-title">添加待入职</span>
        </template>
      </van-cell>
    </div>
    <div class="person_cards">
      <van-cell-group v-for="(item, index) in 3" :key="index">
        <van-cell @click="showPersonalInf(item)">
          <template #title>
            <div class="cell_one">
              <span>徐先生</span>
              <svg-icon icon-class="induction_link"></svg-icon>
              <span>采购员</span>
            </div>
            <div class="cell_two">
              <span>13698172386</span>
              <svg-icon icon-class="interview_tel"></svg-icon>
              <svg-icon icon-class="interview_wx"></svg-icon>
            </div>
            <div class="cell_three">
              <span>3年经验</span>
              <span>|</span>
              <span>大专</span>
            </div>
            <div class="cell_four">
              <span>到岗时间：</span>
              <span>2021-04-11 08:00</span>
            </div>
            <!-- <span class="custom-title">单元格</span> -->
          </template>
          <template #default>
            <div class="head_card">
              <img src="@/assets/img/interview_pic1.png" alt />
            </div>
            <van-button
              plain
              color="#2b8df0"
              type="primary"
              size="mini"
              @click.stop="confirm(item)"
              >确认入职</van-button
            >
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <van-button
      plain
      color="#2b8df0"
      type="primary"
      size="mini"
      @click="setmm(1)"
      >window.location.href</van-button
    >
    <van-button
      plain
      color="#2b8df0"
      type="primary"
      size="mini"
      @click="setmm(2)"
      >window.globalurls</van-button
    >
    <van-button
      plain
      color="#2b8df0"
      type="primary"
      size="mini"
      @click="setmm(3)"
      >http://v8h5.hyclound.cn</van-button
    >
    <van-switch v-model="jm" />
  </div>
</template>

<script>
import myCalendar from '@/components/myCalendar'
import { GetJsSign } from '@api/hp.js'
export default {
  components: {
    myCalendar
  },
  data() {
    return {
      mm: '',
      jm: false
    }
  },
  methods: {
    setmm(i) {
      switch (i) {
        case 1:
          this.mm = window.location.href
          break
        case 2:
          this.mm = window.globalurls
          break
        case 3:
          this.mm = 'http://v8h5.hyclound.cn'
          break

        default:
          break
      }
      this.weixinConfig()
    },
    weixinConfig() {
      // let mm = encodeURIComponent(location.href)
      //在vue里，我们把encodeURIComponent去掉了，反倒问题解决了
      // let mm = encodeURIComponent(window.globalurls)

      // this.mm = window.location.href ///(Android)/i.test(navigator.userAgent) ? href : window.globalurls
      // let mm = location.href
      alert(this.mm)
      GetJsSign({
        param: this.mm
      }).then(res => {
        wx.config({
          debug: false,
          appId: res.appId,
          timestamp: res.timestamp,
          nonceStr: res.nonceStr,
          signature: res.signature,
          jsApiList: ['getLocation']
          // jsApiList: ['chooseWXPay', 'getLocation']
        })

        wx.ready(function() {
          alert('config ready')
          wx.getLocation({
            type: 'gcj02',
            // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
            cache: false,
            success: function(res) {
              alert('success' + res.latitude)
            },
            cancel: function(res) {
              alert('cancel' + JSON.stringify(res))
            }
          })
        })
        wx.error(function(w) {
          console.log('config error', w)
          alert(w.errMsg)
        })
      })
    },
    onClickLeft() {
      this.$router.push('/home')
    },
    onClickRight() {},
    // toViewInfo () {
    //   this.$router.push('/viewInfo')
    // },

    //添加入职信息
    gotoAdd() {
      console.log('去添加入职')
    },
    // 查看个人信息
    showPersonalInf(item) {
      this.$router.push('/inductionInfo')
    },
    // 确认入职
    confirm(item) {
      console.log('确认入职')
    },
    getdate(d) {
      console.log(d)
    }
  }
}
</script>

<style lang="less" scoped>
.induction {
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
  }
  .add_newone {
    .van-cell__title {
      font-family: Source Han Sans CN;
      display: flex;
      align-items: center;
      .svg-icon {
        width: 2em;
        height: 2em;
        margin-right: 1em;
      }
    }
  }
  .person_cards {
    .van-cell-group {
      margin: 15px 0;
      .van-cell__title {
        flex: 2;
      }
      .van-cell__value {
        flex: 0.8;
      }
    }
    .cell_one {
      display: flex;
      align-items: center;
      span {
        font-size: 40px;
        font-family: Source Han Sans CN;
        font-weight: 500;
      }
      .svg-icon {
        margin: 0 20px;
      }
    }
    .cell_two {
      font-family: Source Han Sans CN;
      color: #2b8df0;
      span {
        margin-right: 10px;
      }
      .svg-icon {
        font-size: 36px;
        margin: 0 18px;
      }
    }
    .cell_three {
      font-size: 28px;
      font-family: Source Han Sans CN;
      color: #999;
    }
    .cell_four {
      color: #2b8df0;
      font-family: Source Han Sans CN;
    }
    .van-cell__value {
      display: flex;
      align-items: center;
      flex-direction: column;
      .head_card {
        // position: absolute;
        // top: 38px;
        // right: 30px;
        width: 106px;
        height: 106px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .van-button {
        margin: 18px 0;
      }
    }
  }
}
</style>
